import { Link } from "react-router-dom";
import { ConsultationForm } from "../Api/utc-api";

export default function ConsultationFormsTable({ consultationForms, viewUrl }:
  { consultationForms: ConsultationForm[], viewUrl?: string }) {

  const getConsultationFormUrl = (consultationForm: ConsultationForm) => {
    if (!viewUrl) return '';
    return `${viewUrl}${!viewUrl.endsWith('/') ? '/' : ''}${consultationForm.id}`;
  };

  return (
    <table className="table is-fullwidth">
      <thead>
        <tr>
          <th>Name</th>
          {!!viewUrl && <th></th>}
        </tr>
      </thead>
      <tbody>
        {
          !consultationForms
            ? <tr><td colSpan={6}>Loading...</td></tr>
            : !consultationForms.length
              ? <tr><td colSpan={6}>No consultation forms</td></tr>
              : consultationForms.map(consultationForm => (
                <tr key={consultationForm.id}>
                  <td>{consultationForm.title}</td>
                  {!!viewUrl && <td><Link to={getConsultationFormUrl(consultationForm)}>View</Link></td>}
                </tr>
              ))
        }
      </tbody>
    </table>
  );
};