/* tslint:disable */
/* eslint-disable */
/**
 * UTC API
 * UTC API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ConsultationFormConditionType = {
    Equals: 'equals',
    Includes: 'includes'
} as const;
export type ConsultationFormConditionType = typeof ConsultationFormConditionType[keyof typeof ConsultationFormConditionType];


export function ConsultationFormConditionTypeFromJSON(json: any): ConsultationFormConditionType {
    return ConsultationFormConditionTypeFromJSONTyped(json, false);
}

export function ConsultationFormConditionTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConsultationFormConditionType {
    return json as ConsultationFormConditionType;
}

export function ConsultationFormConditionTypeToJSON(value?: ConsultationFormConditionType | null): any {
    return value as any;
}

