/* tslint:disable */
/* eslint-disable */
/**
 * UTC API
 * UTC API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConsultationFormCondition } from './ConsultationFormCondition';
import {
    ConsultationFormConditionFromJSON,
    ConsultationFormConditionFromJSONTyped,
    ConsultationFormConditionToJSON,
} from './ConsultationFormCondition';

/**
 * 
 * @export
 * @interface ConsultationFormNextStage
 */
export interface ConsultationFormNextStage {
    /**
     * 
     * @type {string}
     * @memberof ConsultationFormNextStage
     */
    stage: string;
    /**
     * 
     * @type {Array<ConsultationFormCondition>}
     * @memberof ConsultationFormNextStage
     */
    conditions?: Array<ConsultationFormCondition>;
}

/**
 * Check if a given object implements the ConsultationFormNextStage interface.
 */
export function instanceOfConsultationFormNextStage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "stage" in value;

    return isInstance;
}

export function ConsultationFormNextStageFromJSON(json: any): ConsultationFormNextStage {
    return ConsultationFormNextStageFromJSONTyped(json, false);
}

export function ConsultationFormNextStageFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConsultationFormNextStage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stage': json['stage'],
        'conditions': !exists(json, 'conditions') ? undefined : ((json['conditions'] as Array<any>).map(ConsultationFormConditionFromJSON)),
    };
}

export function ConsultationFormNextStageToJSON(value?: ConsultationFormNextStage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'stage': value.stage,
        'conditions': value.conditions === undefined ? undefined : ((value.conditions as Array<any>).map(ConsultationFormConditionToJSON)),
    };
}

