import { useState } from 'react';
import "bulma/css/bulma.min.css";
import "@fortawesome/fontawesome-free/css/all.css";
import './App.css';
import { useAuth0 } from '@auth0/auth0-react';
import { UTC_ADMIN_AUTH_DISABLED } from "./config/app-config";
import { Link, Outlet } from 'react-router-dom';
import { userIsAdmin, userIsStaff } from './helpers/auth';

function App() {
  const authState = useAuth0();

  const [showMobileNavigation, setShowMobileNavigation] = useState<boolean>(false);

  const logout = () => authState.logout({ logoutParams: { returnTo: window.location.origin } });

  const toggleMobileNavigation = () => setShowMobileNavigation(!showMobileNavigation);


  if (!UTC_ADMIN_AUTH_DISABLED) {
    authState.getAccessTokenSilently();
    if (authState.error || (!authState.isAuthenticated && !authState.isLoading)) {
      authState.loginWithRedirect();
      return <div></div>;
    }
  }

  if (!authState.user)
    return <div>Loading...</div>;

  if (!userIsAdmin() && !userIsStaff())
    return <div>
      Unauthorised - <button onClick={logout}>Exit</button>
    </div>;

  return (
    <div className="columns is-fullheight">
      <div className={`column is-sidebar-menu ${!showMobileNavigation ? "is-hidden-mobile" : ""}`}>
        <aside className="menu">
          <div className="block sidebar-logo">
            <h1 className="subtitle is-4">
              UTC Administration
            </h1>
          </div>
          <p className="menu-label">
            General
          </p>
          <ul className="menu-list">
            <li><Link to="/">Dashboard</Link></li>
            <li><Link to="/your-centres">Your Centres</Link></li>
          </ul>
          {userIsAdmin() && <>
            <p className="menu-label">
              Admin
            </p>
            <ul className="menu-list">
              <li><Link to="/admin/manage-centres">Manage Centres</Link></li>
              <li><Link to="/admin/manage-staff">Manage Staff</Link></li>
              <li><Link to="/admin/manage-admins">Manage Admins</Link></li>
              <li><Link to="/admin/manage-consultation-forms">Manage Forms</Link></li>
            </ul>
          </>
          }
          <p className="menu-label">
            Account
          </p>
          <ul className="menu-list">
            <li><Link to="" onClick={logout}>Log Out</Link></li>
          </ul>
        </aside>
      </div>
      <div className="column is-main-content">
        <div className="is-mobile-nav-toggle is-hidden-tablet" onClick={toggleMobileNavigation}>
          <i className="fas fa-bars"></i>
        </div>
        <Outlet />
      </div>
    </div>
  );
}

export default App;
