import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { UtcApiClient } from "../../Api/UtcApiClient";
import { ConsultationFormInput, ConsultationFormsApi } from "../../Api/utc-api";
import { useState } from "react";
import ConsultationFormsTable from "../../Components/ConsultationFormsTable";
import ConsultationFormEditor from "../../Components/ConsultationFormEditor";

export default function ManageConsultationFormsView() {
  const [showAddConsultationForm, setShowAddConsultationForm] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const { data: consultationForms } = useQuery({
    queryKey: ['consultationForms'],
    queryFn: async () => {
      const consultationFormsApi = await UtcApiClient.getResourceClient(ConsultationFormsApi);
      return await consultationFormsApi.getConsultationForms();
    },
    refetchOnWindowFocus: false
  });

  const { mutate: addConsultationForm } = useMutation({
    mutationFn: async (newConsultationForm: ConsultationFormInput) => {
      const consultationFormsApi = await UtcApiClient.getResourceClient(ConsultationFormsApi);
      await consultationFormsApi.addConsultationForm({ consultationFormInput: newConsultationForm });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['consultationForms']);
      setShowAddConsultationForm(false);
    },
    onError: () => {
      alert("An error occurred while adding consultation form");
    }
  });

  return <>
    <h1 className="title is-2">Manage Consultation Forms</h1>

    {
      showAddConsultationForm
        ? <>
          <ConsultationFormEditor
            consultationForm={{ title: '', body: { stages: [] } }}
            onSave={newConsultationForm => addConsultationForm(newConsultationForm)}
          />
          <p>
            <button className="button" onClick={() => setShowAddConsultationForm(false)}>Cancel</button>
          </p>
        </>
        : !consultationForms
          ? <>Loading...</>
          : <>
            <button className="button is-success mb-2" onClick={() => setShowAddConsultationForm(true)}>Add Consultation Form</button>
            <ConsultationFormsTable consultationForms={consultationForms} viewUrl="/admin/manage-consultation-forms" />
          </>
    }
  </>;
};