import { ChangeEvent, useEffect, useState } from "react";
import { ConsultationFormInput } from "../Api/utc-api";
import { generateUniqueId } from "../helpers/general";

const copy = (input: Object) => JSON.parse(JSON.stringify(input));

export default function ConsultationFormEditor({ consultationForm, onSave }:
  { consultationForm: ConsultationFormInput, onSave: (input: ConsultationFormInput) => void }) {

  const [editedConsultationForm, setEditedConsultationForm] = useState<ConsultationFormInput>(copy(consultationForm));

  useEffect(() => {
    setEditedConsultationForm(copy(consultationForm));
  }, [consultationForm]);


  // edit consultation
  const consultationFormTitleChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const consultationFormTitle = e.target.value;

    setEditedConsultationForm(f => {
      f.title = consultationFormTitle;
      return { ...f };
    });
  };


  // edit stages
  const stageNameChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const stageName = e.target.value;
    const stageIndex = e.target.getAttribute('stage-index');
    if (!stageIndex) return;

    setEditedConsultationForm(f => {
      f.body.stages[parseInt(stageIndex)].name = stageName;
      return { ...f };
    });
  };

  const addStage = () => {
    setEditedConsultationForm(f => ({
      ...f,
      body: {
        ...f.body,
        stages: [
          ...f.body.stages,
          { name: '', questions: [] }
        ]
      }
    }));
  };

  const deleteStage = (stageIndex: number) => {
    const stages = [...editedConsultationForm.body.stages];
    stages.splice(stageIndex, 1);

    setEditedConsultationForm(f => ({
      ...f,
      body: { ...f.body, stages: [...stages] }
    }));
  };

  const shiftStage = (from: number, to: number) => {
    const stages = [...editedConsultationForm.body.stages];
    stages.splice(to, 0, ...stages.splice(from, 1));

    setEditedConsultationForm(f => ({
      ...f,
      body: { ...f.body, stages: [...stages] }
    }));
  };

  const moveStageUp = (stageIndex: number) => {
    if (stageIndex > 0)
      shiftStage(stageIndex, stageIndex - 1);
  };

  const moveStageDown = (stageIndex: number) => {
    if (stageIndex < editedConsultationForm.body.stages.length - 1)
      shiftStage(stageIndex, stageIndex + 1);
  };


  // edit questions
  const questionTextChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const questionText = e.target.value;
    const stageIndex = e.target.getAttribute('stage-index');
    const questionIndex = e.target.getAttribute('question-index');
    if (!stageIndex || !questionIndex) return;

    setEditedConsultationForm(f => {
      f.body.stages[parseInt(stageIndex)].questions[parseInt(questionIndex)].question = questionText;
      return { ...f };
    });
  };

  const addQuestion = (stageIndex: number) => {
    const stages = [...editedConsultationForm.body.stages];
    const questions = stages[stageIndex].questions;

    let id : string | null = null;

    while (!id) {
      const generatedId = generateUniqueId();
      if (!questions.find(_ => _.id === generatedId))
        id = generatedId;
    }

    questions.push({ id, question: '', type: 'text', });

    setEditedConsultationForm(f => ({
      ...f,
      body: { ...f.body, stages }
    }));
  };

  const deleteQuestion = (stageIndex: number, questionIndex: number) => {
    const stages = [...editedConsultationForm.body.stages];
    const questions = stages[stageIndex].questions;
    questions.splice(questionIndex, 1);

    setEditedConsultationForm(f => ({
      ...f,
      body: { ...f.body, stages: [...stages] }
    }));
  };

  const shiftQuestion = (stageIndex: number, from: number, to: number) => {
    const stages = [...editedConsultationForm.body.stages];
    const questions = stages[stageIndex].questions;
    questions.splice(to, 0, ...questions.splice(from, 1));

    setEditedConsultationForm(f => ({
      ...f,
      body: { ...f.body, stages: [...stages] }
    }));
  };

  const moveQuestionUp = (stageIndex: number, questionIndex: number) => {
    if (questionIndex > 0)
      shiftQuestion(stageIndex, questionIndex, questionIndex - 1);
  };

  const moveQuestionDown = (stageIndex: number, questionIndex: number) => {
    if (questionIndex < editedConsultationForm.body.stages[stageIndex].questions.length - 1)
      shiftQuestion(stageIndex, questionIndex, questionIndex + 1);
  };


  return <>
    <p className="mb-3 has-text-weight-bold">
      Consultation Form Title:
      <input type="text" className="input" value={editedConsultationForm.title} onChange={consultationFormTitleChanged} />
    </p>

    {editedConsultationForm.body.stages.map((stage, stageIndex) =>
      <div key={stageIndex} className="section-container mb-5">
        <div className="columns">
          <div className="column">
            <h5 className="title is-5">Stage {stageIndex + 1}</h5>
          </div>
          <div className="column">
            <button className="button" onClick={() => moveStageUp(stageIndex)} disabled={stageIndex === 0}>
              <i className="fas fa-angles-up"></i> Move Up</button>

            &nbsp;&nbsp;

            <button className="button" onClick={() => moveStageDown(stageIndex)} disabled={stageIndex >= editedConsultationForm.body.stages.length - 1}>
              <i className="fas fa-angles-down"></i> Move Down
            </button>

            &nbsp;&nbsp;&nbsp;

            <button className="button is-danger" onClick={() => deleteStage(stageIndex)}>Delete</button>
          </div>
        </div>

        <p className="mb-5">
          <span className="has-text-weight-bold">Stage Name:</span>
          <input type="text" className="input" stage-index={stageIndex} value={stage.name} onChange={stageNameChanged} />
        </p>

        <div>
          {stage.questions.map((question, questionIndex) =>
            <div key={questionIndex}>
              <div className="columns mt-2 mb-0 pb-0">
                <div className="column pb-0">Question {questionIndex + 1}: </div>
                <div className="column pb-0">
                  <button className="button" onClick={() => moveQuestionUp(stageIndex, questionIndex)} disabled={questionIndex === 0}>
                    <i className="fas fa-angles-up"></i> Move Up
                  </button>

                  &nbsp;&nbsp;

                  <button className="button" onClick={() => moveQuestionDown(stageIndex, questionIndex)} disabled={questionIndex >= editedConsultationForm.body.stages[stageIndex].questions.length - 1}>
                    <i className="fas fa-angles-down"></i> Move Down
                  </button>

                  &nbsp;&nbsp;&nbsp;

                  <button className="button is-danger" onClick={() => deleteQuestion(stageIndex, questionIndex)}>Delete</button>
                </div>
              </div>
              <input type="text" className="input" stage-index={stageIndex} question-index={questionIndex}
                value={question.question} onChange={questionTextChanged} />
            </div>)}

          <div className="mt-5">
            <button className="button" onClick={() => addQuestion(stageIndex)}>Add Question</button>
          </div>
        </div>

      </div >)
    }

    <div className="mb-5">
      <button className="button" onClick={() => addStage()}>Add Stage</button>
    </div>

    <button className="button is-success" onClick={() => onSave(editedConsultationForm)}>Save</button>
  </>;
};