/* tslint:disable */
/* eslint-disable */
/**
 * UTC API
 * UTC API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConsultationFormNextStage } from './ConsultationFormNextStage';
import {
    ConsultationFormNextStageFromJSON,
    ConsultationFormNextStageFromJSONTyped,
    ConsultationFormNextStageToJSON,
} from './ConsultationFormNextStage';
import type { ConsultationFormQuestion } from './ConsultationFormQuestion';
import {
    ConsultationFormQuestionFromJSON,
    ConsultationFormQuestionFromJSONTyped,
    ConsultationFormQuestionToJSON,
} from './ConsultationFormQuestion';

/**
 * 
 * @export
 * @interface ConsultationFormStage
 */
export interface ConsultationFormStage {
    /**
     * 
     * @type {string}
     * @memberof ConsultationFormStage
     */
    name: string;
    /**
     * 
     * @type {Array<ConsultationFormQuestion>}
     * @memberof ConsultationFormStage
     */
    questions: Array<ConsultationFormQuestion>;
    /**
     * 
     * @type {Array<ConsultationFormNextStage>}
     * @memberof ConsultationFormStage
     */
    nextStage?: Array<ConsultationFormNextStage>;
}

/**
 * Check if a given object implements the ConsultationFormStage interface.
 */
export function instanceOfConsultationFormStage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "questions" in value;

    return isInstance;
}

export function ConsultationFormStageFromJSON(json: any): ConsultationFormStage {
    return ConsultationFormStageFromJSONTyped(json, false);
}

export function ConsultationFormStageFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConsultationFormStage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'questions': ((json['questions'] as Array<any>).map(ConsultationFormQuestionFromJSON)),
        'nextStage': !exists(json, 'nextStage') ? undefined : ((json['nextStage'] as Array<any>).map(ConsultationFormNextStageFromJSON)),
    };
}

export function ConsultationFormStageToJSON(value?: ConsultationFormStage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'questions': ((value.questions as Array<any>).map(ConsultationFormQuestionToJSON)),
        'nextStage': value.nextStage === undefined ? undefined : ((value.nextStage as Array<any>).map(ConsultationFormNextStageToJSON)),
    };
}

