import { ConsultationFormInput, ConsultationFormsApi } from "../../Api/utc-api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { handleApiError } from "../../Api/http";
import { UtcApiClient } from "../../Api/UtcApiClient";
import { useNavigate, useParams } from "react-router-dom";
import ConsultationFormEditor from "../../Components/ConsultationFormEditor";

export default function ManageConsultationFormView() {
  const { id } = useParams();
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { mutate: updateConsultationForm } = useMutation({
    mutationFn: async (editedConsultationForm: ConsultationFormInput) => {
      if (!id)
        throw new Error("Invalid data");

      const consultationFormsApi = await UtcApiClient.getResourceClient(ConsultationFormsApi);
      await consultationFormsApi.updateConsultationForm({ id, consultationFormInput: editedConsultationForm });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['consultationForms']);
      navigate('/admin/manage-consultation-forms');
    },
    onError: () => {
      alert("An error occurred while updating consultationForm");
    }
  });

  const { mutate: deleteConsultationForm } = useMutation({
    mutationFn: async () => {
      if (!id)
        throw new Error("Invalid data");

      const consultationFormsApi = await UtcApiClient.getResourceClient(ConsultationFormsApi);
      await consultationFormsApi.deleteConsultationForm({ id });
    },
    onSuccess: () => {
      navigate('/admin/manage-consultation-forms');
      setTimeout(() => queryClient.invalidateQueries(['consultationForms']), 500);
    },
    onError: () => {
      alert("An error occurred while deleting consultationForm");
    }
  });

  const { data: consultationForm } = useQuery({
    queryKey: ['consultationForms', { consultationFormId: id }],
    queryFn: async () => {
      try {
        const consultationFormsApi = await UtcApiClient.getResourceClient(ConsultationFormsApi);
        return await consultationFormsApi.getConsultationForm({ id: id as string });
      } catch (e) {
        handleApiError(e);
        throw e;
      }
    },
    enabled: !!id,
    refetchOnWindowFocus: false
  });

  if (!consultationForm)
    return <>Loading...</>;

  return <>
    <div className="">
      <ConsultationFormEditor
        consultationForm={consultationForm}
        onSave={editedConsultationForm => updateConsultationForm(editedConsultationForm)}
      />

      <button className="button is-danger ml-2" onClick={() => {
        if (window.confirm("Are you sure you wish to delete this consultation form?"))
          deleteConsultationForm();
      }}>Delete</button>
    </div>
  </>;
};