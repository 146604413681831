import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import ErrorPage from "../Views/ErrorPage";
import DashboardView from "../Views/DashboardView";
import YourCentresView from "../Views/YourCentresView";
import YourCentreView from "../Views/YourCentreView";
import ConsultationSubmissionView from "../Views/ConsultationSubmissionView";
import ManageCentresView from "../Views/Admin/ManageCentresView";
import ManageStaffView from "../Views/Admin/ManageStaffView";
import AdminView from "../Views/Admin/AdminView";
import ManageCentreView from "../Views/Admin/ManageCentreView";
import ManageStaffMemberView from "../Views/Admin/ManageStaffMemberView";
import ManageAdminsView from "../Views/Admin/ManageAdminsView";
import ManageAdminView from "../Views/Admin/ManageAdminView";
import ManageConsultationFormsView from "../Views/Admin/ManageConsultationFormsView";
import ManageConsultationFormView from "../Views/Admin/ManageConsultationFormView";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <DashboardView />,
      },
      {
        path: "/your-centres",
        element: <YourCentresView />,
      },
      {
        path: "/your-centres/:id",
        element: <YourCentreView />,
      },
      {
        path: "/consultation-submission/:id",
        element: <ConsultationSubmissionView />,
      },
      {
        path: "admin",
        element: <AdminView />,
        children: [
          {
            path: "manage-centres",
            element: <ManageCentresView />,
          },
          {
            path: "manage-centres/:id",
            element: <ManageCentreView />,
          },
          {
            path: "manage-staff",
            element: <ManageStaffView />,
          },
          {
            path: "manage-staff/:id",
            element: <ManageStaffMemberView />,
          },
          {
            path: "manage-admins",
            element: <ManageAdminsView />,
          },
          {
            path: "manage-admins/:id",
            element: <ManageAdminView />,
          },
          {
            path: "manage-consultation-forms",
            element: <ManageConsultationFormsView />,
          },
          {
            path: "manage-consultation-forms/:id",
            element: <ManageConsultationFormView />,
          }
        ]
      },
    ],
  },
]);

export default router;