/* tslint:disable */
/* eslint-disable */
/**
 * UTC API
 * UTC API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConsultationFormCondition } from './ConsultationFormCondition';
import {
    ConsultationFormConditionFromJSON,
    ConsultationFormConditionFromJSONTyped,
    ConsultationFormConditionToJSON,
} from './ConsultationFormCondition';
import type { ConsultationFormQuestionType } from './ConsultationFormQuestionType';
import {
    ConsultationFormQuestionTypeFromJSON,
    ConsultationFormQuestionTypeFromJSONTyped,
    ConsultationFormQuestionTypeToJSON,
} from './ConsultationFormQuestionType';

/**
 * 
 * @export
 * @interface ConsultationFormQuestion
 */
export interface ConsultationFormQuestion {
    /**
     * 
     * @type {string}
     * @memberof ConsultationFormQuestion
     */
    id: string;
    /**
     * 
     * @type {ConsultationFormQuestionType}
     * @memberof ConsultationFormQuestion
     */
    type: ConsultationFormQuestionType;
    /**
     * 
     * @type {string}
     * @memberof ConsultationFormQuestion
     */
    question: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ConsultationFormQuestion
     */
    rules?: Array<string>;
    /**
     * 
     * @type {Array<ConsultationFormCondition>}
     * @memberof ConsultationFormQuestion
     */
    conditions?: Array<ConsultationFormCondition>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ConsultationFormQuestion
     */
    answers?: Array<string>;
}

/**
 * Check if a given object implements the ConsultationFormQuestion interface.
 */
export function instanceOfConsultationFormQuestion(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "question" in value;

    return isInstance;
}

export function ConsultationFormQuestionFromJSON(json: any): ConsultationFormQuestion {
    return ConsultationFormQuestionFromJSONTyped(json, false);
}

export function ConsultationFormQuestionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConsultationFormQuestion {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': ConsultationFormQuestionTypeFromJSON(json['type']),
        'question': json['question'],
        'rules': !exists(json, 'rules') ? undefined : json['rules'],
        'conditions': !exists(json, 'conditions') ? undefined : ((json['conditions'] as Array<any>).map(ConsultationFormConditionFromJSON)),
        'answers': !exists(json, 'answers') ? undefined : json['answers'],
    };
}

export function ConsultationFormQuestionToJSON(value?: ConsultationFormQuestion | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': ConsultationFormQuestionTypeToJSON(value.type),
        'question': value.question,
        'rules': value.rules,
        'conditions': value.conditions === undefined ? undefined : ((value.conditions as Array<any>).map(ConsultationFormConditionToJSON)),
        'answers': value.answers,
    };
}

