/* tslint:disable */
/* eslint-disable */
/**
 * UTC API
 * UTC API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ConsultationForm,
  ConsultationFormInput,
} from '../models';
import {
    ConsultationFormFromJSON,
    ConsultationFormToJSON,
    ConsultationFormInputFromJSON,
    ConsultationFormInputToJSON,
} from '../models';

export interface AddConsultationFormRequest {
    consultationFormInput: ConsultationFormInput;
}

export interface DeleteConsultationFormRequest {
    id: string;
}

export interface GetConsultationFormRequest {
    id: string;
}

export interface UpdateConsultationFormRequest {
    id: string;
    consultationFormInput: ConsultationFormInput;
}

/**
 * 
 */
export class ConsultationFormsApi extends runtime.BaseAPI {

    /**
     * Add consultation form
     */
    async addConsultationFormRaw(requestParameters: AddConsultationFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConsultationForm>> {
        if (requestParameters.consultationFormInput === null || requestParameters.consultationFormInput === undefined) {
            throw new runtime.RequiredError('consultationFormInput','Required parameter requestParameters.consultationFormInput was null or undefined when calling addConsultationForm.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oAuth2", []);
        }

        const response = await this.request({
            path: `/consultation-forms`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConsultationFormInputToJSON(requestParameters.consultationFormInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConsultationFormFromJSON(jsonValue));
    }

    /**
     * Add consultation form
     */
    async addConsultationForm(requestParameters: AddConsultationFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConsultationForm> {
        const response = await this.addConsultationFormRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete consultation form
     */
    async deleteConsultationFormRaw(requestParameters: DeleteConsultationFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteConsultationForm.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oAuth2", []);
        }

        const response = await this.request({
            path: `/consultation-forms/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete consultation form
     */
    async deleteConsultationForm(requestParameters: DeleteConsultationFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteConsultationFormRaw(requestParameters, initOverrides);
    }

    /**
     * Get consultation form
     */
    async getConsultationFormRaw(requestParameters: GetConsultationFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConsultationForm>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getConsultationForm.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oAuth2", []);
        }

        const response = await this.request({
            path: `/consultation-forms/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConsultationFormFromJSON(jsonValue));
    }

    /**
     * Get consultation form
     */
    async getConsultationForm(requestParameters: GetConsultationFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConsultationForm> {
        const response = await this.getConsultationFormRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get consultation forms
     */
    async getConsultationFormsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ConsultationForm>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oAuth2", []);
        }

        const response = await this.request({
            path: `/consultation-forms`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ConsultationFormFromJSON));
    }

    /**
     * Get consultation forms
     */
    async getConsultationForms(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ConsultationForm>> {
        const response = await this.getConsultationFormsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update consultation form
     */
    async updateConsultationFormRaw(requestParameters: UpdateConsultationFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateConsultationForm.');
        }

        if (requestParameters.consultationFormInput === null || requestParameters.consultationFormInput === undefined) {
            throw new runtime.RequiredError('consultationFormInput','Required parameter requestParameters.consultationFormInput was null or undefined when calling updateConsultationForm.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oAuth2", []);
        }

        const response = await this.request({
            path: `/consultation-forms/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ConsultationFormInputToJSON(requestParameters.consultationFormInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update consultation form
     */
    async updateConsultationForm(requestParameters: UpdateConsultationFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateConsultationFormRaw(requestParameters, initOverrides);
    }

}
