/* tslint:disable */
/* eslint-disable */
/**
 * UTC API
 * UTC API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConsultationFormConditionType } from './ConsultationFormConditionType';
import {
    ConsultationFormConditionTypeFromJSON,
    ConsultationFormConditionTypeFromJSONTyped,
    ConsultationFormConditionTypeToJSON,
} from './ConsultationFormConditionType';

/**
 * 
 * @export
 * @interface ConsultationFormCondition
 */
export interface ConsultationFormCondition {
    /**
     * 
     * @type {string}
     * @memberof ConsultationFormCondition
     */
    stage: string;
    /**
     * 
     * @type {string}
     * @memberof ConsultationFormCondition
     */
    question: string;
    /**
     * 
     * @type {ConsultationFormConditionType}
     * @memberof ConsultationFormCondition
     */
    operator: ConsultationFormConditionType;
    /**
     * 
     * @type {string}
     * @memberof ConsultationFormCondition
     */
    value: string;
}

/**
 * Check if a given object implements the ConsultationFormCondition interface.
 */
export function instanceOfConsultationFormCondition(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "stage" in value;
    isInstance = isInstance && "question" in value;
    isInstance = isInstance && "operator" in value;
    isInstance = isInstance && "value" in value;

    return isInstance;
}

export function ConsultationFormConditionFromJSON(json: any): ConsultationFormCondition {
    return ConsultationFormConditionFromJSONTyped(json, false);
}

export function ConsultationFormConditionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConsultationFormCondition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stage': json['stage'],
        'question': json['question'],
        'operator': ConsultationFormConditionTypeFromJSON(json['operator']),
        'value': json['value'],
    };
}

export function ConsultationFormConditionToJSON(value?: ConsultationFormCondition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'stage': value.stage,
        'question': value.question,
        'operator': ConsultationFormConditionTypeToJSON(value.operator),
        'value': value.value,
    };
}

