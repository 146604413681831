/* tslint:disable */
/* eslint-disable */
/**
 * UTC API
 * UTC API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConsultationFormBody } from './ConsultationFormBody';
import {
    ConsultationFormBodyFromJSON,
    ConsultationFormBodyFromJSONTyped,
    ConsultationFormBodyToJSON,
} from './ConsultationFormBody';

/**
 * 
 * @export
 * @interface ConsultationFormInput
 */
export interface ConsultationFormInput {
    /**
     * 
     * @type {string}
     * @memberof ConsultationFormInput
     */
    title: string;
    /**
     * 
     * @type {ConsultationFormBody}
     * @memberof ConsultationFormInput
     */
    body: ConsultationFormBody;
}

/**
 * Check if a given object implements the ConsultationFormInput interface.
 */
export function instanceOfConsultationFormInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "body" in value;

    return isInstance;
}

export function ConsultationFormInputFromJSON(json: any): ConsultationFormInput {
    return ConsultationFormInputFromJSONTyped(json, false);
}

export function ConsultationFormInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConsultationFormInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': json['title'],
        'body': ConsultationFormBodyFromJSON(json['body']),
    };
}

export function ConsultationFormInputToJSON(value?: ConsultationFormInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'body': ConsultationFormBodyToJSON(value.body),
    };
}

